import React from 'react'
import { Link } from 'gatsby'
import { CardColumns, Card } from 'react-bootstrap'

import './style.css'

function Genres({genres}){
    const bgColors1 = ['#8AACF5','#70C9B1','#9477D5','#9DC3B5','#DF8186','#FC6B3E','#E5AA37','#30A3DB','#E16BE1','#FAA296', '#5B8292']
    const bgColors = ['#E5AA37','#30A3DB','#5B8292', '#DF8186']
    const bgColorsLen = bgColors.length
    return(
        <CardColumns className="categorycards">
        <ul style={{ padding: 0, margin: 0}}>
            {genres.map(genre => {
                const bgColor = bgColors[Math.floor(Math.random()*bgColorsLen)]
            return (
                <li
                key={genre.id}
                style={{
                    listStyle: "none",
                    display: "block",
                    fontSize:"1.5em",
                    marginBottom: "1.25rem"
                }}
                >
                    <Link to={"/best-books/" + genre.urlName}>
                    <Card className="box" style={{borderColor:bgColor}}>
                        <Card.Body style={{ margin:"auto", padding:"1rem 0 !important"}}>
                            <Card.Text className="category">
                                {genre.displayName}
                            </Card.Text>
                        </Card.Body>
                    </Card> 
                    </Link>      
                </li>
            )
        })}
        </ul>
        </CardColumns>
    )
}

export default Genres;