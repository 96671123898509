import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import { ExploreT } from "../services/seotemplate"
import Genres from '../components/genres'

import '../styles/global.css'

const Explore = ({data}) => {
  const title = ExploreT.title
  const description = ExploreT.description
  const heading = ExploreT.heading

  const schema = 
  {
    "@context": "https://schema.org",
    "@type": "WebPage",
    "name": title,
    "description": description
  }

    return (
    <Layout>
      <SEO title={title} description={description} schemaMarkup={schema}/>
      <h1 className="heading">{heading}</h1>
      <Genres genres={data.categories.nodes}/>
    </Layout>)
}

export const query = graphql `
{
    categories:allCategories {
      nodes {
        id
        urlName
        displayName
      }
    }
    site: site {
      siteMetadata {
        siteUrl
        title
        description
      }
    } 
  }
`

export default Explore;